
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class BannerNotification extends Vue {
  @Prop({ default: "" }) color!: string;
  @Prop({ default: "" }) message!: string;

  public show = true;
}
