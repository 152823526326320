
import { Member } from "@/models";
import { Component, Vue, Emit, Prop, Watch } from "vue-property-decorator";

@Component
export default class NewGameModal extends Vue {
  @Prop({ default: false }) isOpened!: boolean;
  @Prop({ default: () => [] }) players!: Member[];
  @Prop({ default: "" }) title!: string;
  @Prop({ default: "" }) location!: string;
  @Prop({ default: () => [2, 3, 4] }) nrOfPlayers!: number[];
  @Prop({ default: true }) disableSaveBtn!: boolean;

  selectedNrOfPlayers = "4";
  selectedLocation = "";

  locationOptions: string[] = [];

  @Watch("players", { immediate: true, deep: true })
  onPlayersInputChange(newVal: Member[]) {
    if (newVal) {
      this.locationOptions = [
        ...newVal.map((member) => member.username),
        "BGA",
      ];
    }
  }

  @Emit() playersChanged(event: any): number {
    return event.target.value;
  }

  @Emit() locationChanged(event: any): string {
    return event.target.value;
  }

  @Emit() timeChanged(event: any): number {
    return event.target.value;
  }

  @Emit() gameSaved(): boolean {
    this.selectedNrOfPlayers = "";
    return true;
  }

  @Emit() closed(): boolean {
    return true;
  }

  public mounted(): void {
    this.playersChanged({ target: { value: this.selectedNrOfPlayers } });
  }
}
