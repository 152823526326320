import { render, staticRenderFns } from "./GameScores.vue?vue&type=template&id=419560bd&scoped=true&"
import script from "./GameScores.vue?vue&type=script&lang=ts&"
export * from "./GameScores.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "419560bd",
  null
  
)

export default component.exports