
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { TableHeading } from "@/models";

@Component
export default class Table extends Vue {
  @Prop({ default: () => [] }) headings!: TableHeading[];
  @Prop({ default: () => [] }) data!: any[];

  @Emit() rowClicked(row: number) {
    return row;
  }
}
