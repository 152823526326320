
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class CountdownCircle extends Vue {
  @Prop({ default: 0 }) timeLeft!: number;
  @Prop({ default: 0 }) timeStart!: number;
  @Prop({ default: "" }) label!: string;

  FULL_DASH_ARRAY = 283;

  formattedTimeLeft() {
    let formattedTime: any = this.timeLeft;

    if (formattedTime < 10) {
      formattedTime = `0${formattedTime}`;
    }
    return formattedTime;
  }

  // Update the dasharray value as time passes, starting with 283
  circleDasharray() {
    return `${(this.timeFraction() * this.FULL_DASH_ARRAY).toFixed(0)} 283`;
  }

  timeFraction() {
    const rawTimeFraction = this.timeLeft / this.timeStart;
    return rawTimeFraction - (1 / this.timeStart) * (1 - rawTimeFraction);
  }
}
